.HomePage {
  overflow: hidden;
}
.padder {
  padding-bottom: 1000vh;
}
.Navbox {
  background-color: black;
  opacity: 0.85;
}
.hero-image {
  overflow: hidden;
  position: relative;
}
.mainImg {
  width: 100vw;
  overflow: hidden;
}
.phoneImg {
  display: none;
}

@media screen and (max-width: 864px) {
  .phoneImg {
    display: block;
    width: 100vw;
  }
  .mainImg {
    display: none;
  }
}

/*txt*/
.txt {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  top: 30vh;
  padding-bottom: 10vh;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  width: 100vw;
  color: black;
}
.btnbox {
  position: relative;
  top: 5vh;
  background-color: rgb(121, 205, 216);
  width: fit-content;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 35px;
  color: whitesmoke;
}
.btnbox:hover {
  transform: scale(1.5);
}
