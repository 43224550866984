.aboutPage {
  overflow: hidden;
  height: 1000vh;
}
.padder {
  padding-bottom: 1000vh;
}
.Navbox {
  background-color: black;
  opacity: 0.85;
}
.hero-image {
  overflow: hidden;
  position: absolute;
}
.mainImg {
  width: 100vw;
}
.phoneImg {
  display: none;
}

@media screen and (max-width: 864px) {
  .phoneImg {
    display: block;
    width: 100vw;
  }
  .mainImg {
    display: none;
  }
}
